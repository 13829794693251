#unity-canvas.full {
    height: 100%;
    width: 100%;
}

#unity-canvas.desktop-width {
    aspect-ratio: 1920 / 1080;
    width: 100%;
}

#unity-canvas.desktop-height {
    aspect-ratio: 1920 / 1080;
    height: 100%;
}
