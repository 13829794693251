#logo-container {
    background-color: #090f1e;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

#logo-parent {
    position: fixed;
    display: grid;
    width: 100%;
    height: 100%;
    align-items: center;
    background-color: #090f1e;
    grid-template-columns: 1fr 160px 1fr;
}

#logo-parent > * {
    grid-column: 2;
}

#logo-wrapper {
    position: relative;
}

#logo {
    width: 100%;
}

@keyframes coinAnimation {
    to {
        background-position: 900px 0;
    }
}

#coin {
    position: absolute;
    width: 18px;
    height: 28px;
    background-image: url("./coin.png");
    background-size: 900px 28px;
    top: -12px;
    left: 96.8px;
    animation: coinAnimation 1.2s steps(50) infinite forwards;
}
