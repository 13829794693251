#unity-progress-bar-bg {
    position: absolute;
    bottom: 0;
    background-color: #868483;
    height: 4px;
    width: 150px;
}

#unity-progress-bar {
    position: absolute;
    bottom: 4px;
    background-color: #FFF;
    height: 10px;
    width: 30%;
    left: 0;
}

#unity-progress-text {
    position: absolute;
    bottom: 14px;
    color: #FFF;
    font-size: 20px;
    left: 50%;
    width: 180px;
    transform: translate(-50%, -50%);
}

#unity-progress-wrapper {
    position: fixed;
    left: calc(50% - 75px);
    top: calc(50% - 75px + 50px);
    width: 150px;
    height: 150px;
}