html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0;
    background-color: #090f1e;
    overflow: hidden;
}

#root {
    width: 100%;
    height: 100%;
}

#game-wrapper {
    width: 100%;
    height: 100%;
}

.unity-canvas {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: default;
}
